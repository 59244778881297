window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-change', (event) => {
    // Hier wird angenommen, dass event.detail die Antworten in einem bestimmten Format enthält.
    // Je nachdem, wie Ihre Daten tatsächlich strukturiert sind, müssen Sie möglicherweise Anpassungen vornehmen.
    console.log(event.detail.fields)
    const dataFields = event.detail.fields;
    let answer1, answer2, answer3;
    if(dataFields.filter(item => item.variable == 'answer1')){
        answer1 = dataFields.filter(item => item.variable == 'answer1')[0].value;
        allanswer1 = true;
        console.log(answer1)
    }
    if(dataFields.filter(item => item.variable == 'answer2')){
        answer2 = dataFields.filter(item => item.variable == 'answer2')[0].value;
        allanswer2 = true;
        console.log(answer2)
    }
    if(dataFields.filter(item => item.variable == 'answer3')){
        answer3 = dataFields.filter(item => item.variable == 'answer3')[0].value;
        allanswer3 = true;
        console.log(answer3)
    }
    // const answer1 = event.detail.answer1; 
    // const answer2 = event.detail.answer2; 
    // const answer3 = event.detail.answer3; 

    const ergebnis = getErgebnis(answer1, answer2, answer3);
    
    document.querySelector('[data-variable="ergebnisse"]').value = ergebnis;

    console.log(ergebnis); 
    // alert(ergebnis); 
});

function getErgebnis(answer1, answer2, answer3) {
    let key = answer1 + answer2 + answer3;
    let ergebnisMap = {
        "aaa": "Deine jährliche Ersparnis beträgt rund € 800,-.",
        "baa": "Deine jährliche Ersparnis beträgt rund € 1.040,-.",
        "caa": "Deine jährliche Ersparnis beträgt rund € 1.600,-.",
        "daa": "Deine jährliche Ersparnis beträgt rund € 2.400,-.",
        "eaa": "Dein Stromverbrauch ist höher als üblicherweise im privaten Bereich. Gerne setzen wir uns bezüglich deiner individuellen Lösung mit dir in Kontakt.",
        "aab": "Deine jährliche Ersparnis beträgt rund € 850,-.",
        "bab": "Deine jährliche Ersparnis beträgt rund € 1.105,-.",
        "cab": "Deine jährliche Ersparnis beträgt rund € 1.700,-.",
        "dab": "Deine jährliche Ersparnis beträgt rund € 2.550,-.",
        "eab": "Dein Stromverbrauch ist höher als üblicherweise im privaten Bereich. Gerne setzen wir uns bezüglich deiner individuellen Lösung mit dir in Kontakt.",
        "aac": "Deine jährliche Ersparnis beträgt rund € 900,-.",
        "bac": "Deine jährliche Ersparnis beträgt rund € 1.170,-.",
        "cac": "Deine jährliche Ersparnis beträgt rund € 1.800,-.",
        "dac": "Deine jährliche Ersparnis beträgt rund € 2.700,-.",
        "eac": "Dein Stromverbrauch ist höher als üblicherweise im privaten Bereich. Gerne setzen wir uns bezüglich deiner individuellen Lösung mit dir in Kontakt.",
        "aba": "Deine jährliche Ersparnis beträgt rund € 900,-.",
        "bba": "Deine jährliche Ersparnis beträgt rund € 1.170,-.",
        "cba": "Deine jährliche Ersparnis beträgt rund € 1.800,-.",
        "dba": "Deine jährliche Ersparnis beträgt rund € 2.700,-.",
        "eba": "Dein Stromverbrauch ist höher als üblicherweise im privaten Bereich. Gerne setzen wir uns bezüglich deiner individuellen Lösung mit dir in Kontakt.",
        "abb": "Deine jährliche Ersparnis beträgt rund € 950,-.",
        "bbb": "Deine jährliche Ersparnis beträgt rund € 1.235,-.",
        "cbb": "Deine jährliche Ersparnis beträgt rund € 1.900,-.",
        "dbb": "Deine jährliche Ersparnis beträgt rund € 2.850,-.",
        "ebb": "Dein Stromverbrauch ist höher als üblicherweise im privaten Bereich. Gerne setzen wir uns bezüglich deiner individuellen Lösung mit dir in Kontakt.",
        "abc": "Deine jährliche Ersparnis beträgt rund € 1.000,-.",
        "bbc": "Deine jährliche Ersparnis beträgt rund € 1.300,-.",
        "cbc": "Deine jährliche Ersparnis beträgt rund € 2.000,-.",
        "dbc": "Deine jährliche Ersparnis beträgt rund € 3.000,-.",
        "ebc": "Dein Stromverbrauch ist höher als üblicherweise im privaten Bereich. Gerne setzen wir uns bezüglich deiner individuellen Lösung mit dir in Kontakt.",
        "aca": "Deine jährliche Ersparnis beträgt rund € 1.000,-.",
        "bca": "Deine jährliche Ersparnis beträgt rund € 1.300,-.",
        "cca": "Deine jährliche Ersparnis beträgt rund € 2.000,-.",
        "dca": "Deine jährliche Ersparnis beträgt rund € 3.000,-.",
        "eca": "Dein Stromverbrauch ist höher als üblicherweise im privaten Bereich. Gerne setzen wir uns bezüglich deiner individuellen Lösung mit dir in Kontakt.",
        "acb": "Deine jährliche Ersparnis beträgt rund € 1.050,-.",
        "bcb": "Deine jährliche Ersparnis beträgt rund € 1.365,-.",
        "ccb": "Deine jährliche Ersparnis beträgt rund € 2.100,-.",
        "dcb": "Deine jährliche Ersparnis beträgt rund € 3.150,-.",
        "ecb": "Dein Stromverbrauch ist höher als üblicherweise im privaten Bereich. Gerne setzen wir uns bezüglich deiner individuellen Lösung mit dir in Kontakt.",
        "acc": "Deine jährliche Ersparnis beträgt rund € 1.100,-.",
        "bcc": "Deine jährliche Ersparnis beträgt rund € 1.430,-.",
        "ccc": "Deine jährliche Ersparnis beträgt rund € 2.200,-.",
        "dcc": "Deine jährliche Ersparnis beträgt rund € 3.300,-.",
        "ecc": "Dein Stromverbrauch ist höher als üblicherweise im privaten Bereich. Gerne setzen wir uns bezüglich deiner individuellen Lösung mit dir in Kontakt.",
};
    return ergebnisMap[key] || "Kein Ergebnis für diese Kombination.";
}
